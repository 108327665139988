/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcTrackmail } from "@gkuis/gkp-customerconfigtrackmail-ui";

export function configureApplicationCcTrackmail() {
  CcTrackmail.configuration = {
    baseUri: process.env.CCTRACKMAIL_BASE_URI!,
    resourceDataBaseUri: `${process.env.CCTRACKMAIL_BASE_URI!}/v1`,
    validationRulesBaseUri: `${process.env.CCTRACKMAIL_BASE_URI!}/v1`
  };
}
