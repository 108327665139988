/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { PostcardConfiguration, PostcardRootProps, Postcard } from "@gkuis/gkp-postcard-ui";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";
import { EnvConfig } from "../types/EnvConfig";

export function getPostcardConfiguration(): PostcardConfiguration {
  return {
    resourceDataBaseUri: process.env.POSTCARD_RESOURCE_DATA_BASE_URI!,
    validationRulesBaseUri: process.env.POSTCARD_VALIDATION_RULES_BASE_URI!,
    baseUri: process.env.POSTCARD_BASE_URI!,
    imageUri: process.env.POSTCARD_IMAGE_URI!
  };
}

export function configureApplicationPostcard() {
  Postcard.configure(getPostcardConfiguration());
}

export function registerApplicationPostcard(authenticationManager: AuthenticationManager, envConfig: EnvConfig) {
  const postcardRootProps: PostcardRootProps = {
    authenticationManager,
    postcardConfiguration: getPostcardConfiguration(),
    cmsContentRoot: envConfig.cmsContentRoot
  };

  registerApplication({
    name: "@gkuis/gkp-postcard-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-postcard-ui"),
    activeWhen: ["/postcard"],
    customProps: {
      ...postcardRootProps,
      domElementGetter: reactDomElementGetter
    },
  });
}