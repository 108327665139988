/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcBilling } from "@gkuis/gkp-customerconfigbilling-ui";

export function configureApplicationCcBilling() {
  CcBilling.configuration = {
    baseUri: process.env.CCBILLING_BASE_URI!,
    resourceDataBaseUri: `${process.env.CCBILLING_BASE_URI!}/v1`,
    validationRulesBaseUri: `${process.env.CCBILLING_BASE_URI!}/v1`
  };
}
