/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  ReportingProactive,
  ReportingProactiveConfiguration,
  ReportingProactiveRootProps
} from "@gkuis/gkp-reportingproactive-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";
import { EnvConfig } from "../types/EnvConfig";

export function getReportingProactiveConfiguration(): ReportingProactiveConfiguration {
  return {
    baseUriV1: process.env.REPORTINGPROACTIVE_EXTERNAL_BASE_URI_V1!,
    baseUriV2: process.env.REPORTINGPROACTIVE_EXTERNAL_BASE_URI_V2!,
    resourceDataBaseUri: process.env.REPORTINGPROACTIVE_EXTERNAL_RESOURCE_DATA_BASE_URI!,
    validationRulesBaseUri: process.env.REPORTINGPROACTIVE_EXTERNAL_VALIDATION_RULES_BASE_URI!,
    baseUriInternal: ""
  };
}

export function configureApplicationReportingProactive() {
  ReportingProactive.configure(getReportingProactiveConfiguration());
}

export function registerApplicationReportingProactive(authenticationManager: AuthenticationManager, envConfig: EnvConfig) {
  const reportingProactiveRootProps: ReportingProactiveRootProps = {
    authenticationManager,
    reportingProactiveConfiguration: getReportingProactiveConfiguration(),
    cmsContentRoot: envConfig.cmsContentRoot
  };

  registerApplication({
    name: "@gkuis/gkp-reportingproactive-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-reportingproactive-ui"),
    activeWhen: ["/reportingproactive"],
    customProps: {
      ...reportingProactiveRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}