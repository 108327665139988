/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  CustomerSpecificInformation,
  CustomerSpecificInformationConfiguration
} from "@gkuis/gkp-customerspecificinformation-ui";

export function getCustomerSpecificInformationConfiguration(): CustomerSpecificInformationConfiguration {
  return {
    resourceDataBaseUri: process.env.CSI_EXTERNAL_RESOURCE_DATA_BASE_URI!,
    validationRulesBaseUri: process.env.CSI_EXTERNAL_VALIDATION_RULES_BASE_URI!,
    baseUri: process.env.CSI_EXTERNAL_BASE_URI!,
  };
}

export function configureApplicationCustomerSpecificInformation() {
  CustomerSpecificInformation.configure(getCustomerSpecificInformationConfiguration());
}
