/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { Responseplus, ResponseplusConfiguration, ResponseplusRootProps } from "@gkuis/gkp-responseplus-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";

export function getResponseplusConfiguration(): ResponseplusConfiguration {
  return {
    resourceDataBaseUri: process.env.RESPONSEPLUS_RESOURCE_DATA_BASE_URI!,
    validationRulesBaseUri: process.env.RESPONSEPLUS_VALIDATION_RULES_BASE_URI!,
    productsUri: process.env.RESPONSEPLUS_PRODUCTS_URI!,
    frankingPreviewUri: process.env.RESPONSEPLUS_FRANKING_PREVIEW_URI!,
    orderUri: process.env.RESPONSEPLUS_ORDER_URI!,
    customerProfilesUri: process.env.RESPONSEPLUS_CUSTOMER_PROFILES_URI!,
    contractIdUri: process.env.RESPONSEPLUS_CONTRACT_ID_URI!,
    imageUri: process.env.RESPONSEPLUS_IMAGE_URI!
  };
}

export function configureApplicationResponseplus() {
  Responseplus.configure(getResponseplusConfiguration());
}

export function registerApplicationResponseplus(authenticationManager: AuthenticationManager) {
  const responseplusRootProps: ResponseplusRootProps = {
    authenticationManager,
    responseplusConfiguration: getResponseplusConfiguration()
  };

  registerApplication({
    name: "@gkuis/gkp-responseplus-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-responseplus-ui"),
    activeWhen: ["/responseplus"],
    customProps: {
      ...responseplusRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}