/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcAutomationCheck } from "@gkuis/gkp-customerconfigautomationcheck-ui";

export function configureApplicationCcAutomationCheck() {
  CcAutomationCheck.configuration = {
    baseUri: `${process.env.CCAUTOMATIONCHECK_BASE_URI!}`,
    resourceDataBaseUri: `${process.env.CCAUTOMATIONCHECK_BASE_URI!}/v1`,
    validationRulesBaseUri: `${process.env.CCAUTOMATIONCHECK_BASE_URI!}/v1`
  };
}
