/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AuthenticationManagerImpl2, logger } from "@gkuis/gkp-authentication";
import "@webcomponents/url";
import { reaction } from "mobx";
import moment from "moment";
import { getAppNames, SingleSpaCustomEventDetail, start } from "single-spa";
import { configureApplicationBilling, registerApplicationBilling } from "./applications/Billing";
import { registerApplicationContent } from "./applications/Content";
import { configureApplicationCustomerAdministration, registerApplicationCustomerAdministration } from "./applications/CustomerAdministration";
import { registerApplicationFooter } from "./applications/Footer";
import { registerApplicationHeader } from "./applications/Header";
import { registerApplicationLandingPage } from "./applications/LandingPage";
import { configureApplicationLimitedQuantities, registerApplicationLimitedQuantities } from "./applications/LimitedQuantities";
import { registerApplicationLoginPage } from "./applications/LoginPage";
import { configureApplicationNotification, registerApplicationNotification } from "./applications/Notification";
import { configureApplicationOrder, registerApplicationOrder } from "./applications/Order";
import { ordermanagementMailApplicationName, registerApplicationOrdermanagementMail } from "./applications/OrdermanagementMail";
import { configureApplicationPackstation, registerApplicationPackstation } from "./applications/Packstation";
import { configureApplicationPostcard, registerApplicationPostcard } from "./applications/Postcard";
import { configureApplicationPostPayback, registerApplicationPostPayback } from "./applications/PostPayback";
import { configureApplicationReporting, registerApplicationReporting } from "./applications/Reporting";
import {
  configureApplicationReportingProactive,
  registerApplicationReportingProactive
} from "./applications/ReportingProactive";
import { configureApplicationResponseplus, registerApplicationResponseplus } from "./applications/Responseplus";
import { configureApplicationUserExternal, registerApplicationUserExternal } from "./applications/UserExternal";
import "./include-bootstrap.scss";
import { TrackingManager } from "./TrackingManager";
import { EnvConfig } from "./types/EnvConfig";
import { manageVerticalSpaceAfterHeaderAndBeforeFooter } from "./verticalSpaceBetweenHeaderAndFooter";
import { setFeatureToggles } from "@gkuis/gkp-base-widgets";
import i18n from "i18next";
import { i18nInitPromise } from "./i18n";
import "./applications/CustomerConfigDeliveryMonitor";
import { configureApplicationCustomerConfig, registerApplicationCustomerConfig } from "./applications/CustomerConfig";
import { configureApplicationCcAutomationCheck } from "./applications/CustomerConfigAutomationCheck";
import { configureApplicationCcCommissionedDataProcessing } from "./applications/CustomerConfigCommissionedDataProcessing";
import { configureApplicationCcBilling } from "./applications/CustomerConfigBilling";
import { configureApplicationCcDeliveryMonitor } from "./applications/CustomerConfigDeliveryMonitor";
import { configureApplicationCcOrderManagementMail } from "./applications/CustomerConfigOrderManagementMail";
import { configureApplicationCcPackstation } from "./applications/CustomerConfigPackstation";
import { configureApplicationCcReportingCo2 } from "./applications/CustomerConfigReportingCo2";
import { configureApplicationCcResponseplus } from "./applications/CustomerConfigResponseplus";
import { configureApplicationCcShipMail } from "./applications/CustomerConfigShipMail";
import { configureApplicationCcTrackAndTrace } from "./applications/CustomerConfigTrackAndTrace";
import { configureApplicationCcTrackmail } from "./applications/CustomerConfigTrackmail";
import { configureApplicationCustomerSpecificInformation } from "./applications/CustomerSpecificInformation";
import { registerApplicationEPostMailer } from "./applications/EPostMailer";
import { registerApplicationAutomationCheck } from "./applications/AutomationCheck";

const LOG_MODULE = "[externalFrame]";

(async () => {
  let envConfig: EnvConfig;
  try {
    const envConfigResponse = await fetch("/envConfig.json");
    if (!envConfigResponse.ok) {
      // noinspection ExceptionCaughtLocallyJS it does not matter why fetch failed (no 2xx, timeout etc.), we want to execute the catch block
      throw envConfigResponse.status;
    }
    envConfig = await envConfigResponse.json() as EnvConfig;
  } catch (error) {
    throw new Error(`Could not load environment configuration: ${error}`);
  }

  setFeatureToggles(envConfig.featureToggles);

  try {
    const authenticationManager: AuthenticationManagerImpl2 = new AuthenticationManagerImpl2(
        envConfig.keycloakUrl,
        "GkpExternal",
        "gui",
        `${envConfig.cmsContentRoot}/{lng}/geschaeftskunden/portal/kundendesktop/oeffentlich/logoutExternal.html`
    );
    authenticationManager.addEventListener("onAuthLogout", async () => {
      const logoutUrls: string[] = [
        "/vls/gw/vlsweb/Logout",
        "/vls/vc/sso/logout",
        "/dhl-rpi/gw/rpcustomerweb/Logout.action"
      ];
      await Promise.all(logoutUrls.map(url => fetch(url)));
    });
    const registerApplicationsBasedOnScopes = async () => {
      // extend here if a new application should only be loaded on correct scopes (e.g. after terms-of-use-agreements)
      if (!getAppNames().includes(ordermanagementMailApplicationName)) {
        const scopes = await authenticationManager.getScopes();
        if (scopes.includes("ordermanagement_mail:user")) {
          registerApplicationOrdermanagementMail(authenticationManager);
        }
      }
    };
    authenticationManager.addEventListener("onAuthSuccess", registerApplicationsBasedOnScopes);
    authenticationManager.addEventListener("onAuthRefreshSuccess", registerApplicationsBasedOnScopes);

    //TrackingManager adds event listeners onAuthSuccess in constructor. Therefore, instantiate it before calling init on authManager!
    let trackingManager: TrackingManager | undefined = undefined;
    if (envConfig.trackingUrl && envConfig.trackingIntegrityHash) {
      trackingManager = new TrackingManager(
          envConfig.trackingUrl,
          envConfig.trackingConsentScriptId,
          authenticationManager,
          // extend here if a new domain wants to send us tracking events
          [
            new URL(envConfig.cmsContentRoot, window.location.toString()).origin,
            new URL(envConfig.inquiryRequestRoot, window.location.toString()).origin,
          ]
      );
    }

    try {
      await authenticationManager.init();
    } catch (e) {
      logger.error("Error initializing AuthenticationManager:", e);
      // noinspection ExceptionCaughtLocallyJS it does not matter why fetch failed (no 2xx, timeout etc.), we want to execute the catch block
      throw e;
    }

    //wait until i18next is initialized, otherwise i18n.changeLanguage called below results in an error
    await i18nInitPromise;

    //when implementing i18next in all services: check if de is loaded also for english users.
    //if so maybe use localeManager in i18next.ts as language detector?
    reaction(
        () => authenticationManager.language,
        (language) => {
          i18n.changeLanguage(language);
          moment.locale(language.startsWith("en") ? "en-gb" : "de");
        },
        {fireImmediately: true}
    );

    window.addEventListener("single-spa:routing-event", (evt: CustomEvent<SingleSpaCustomEventDetail>) => {
      logger.log(LOG_MODULE, "received single-spa:routing-event", evt);
      manageVerticalSpaceAfterHeaderAndBeforeFooter(authenticationManager.authenticated);
    });
    reaction(
        () => authenticationManager.authenticated,
        isAuthenticated => manageVerticalSpaceAfterHeaderAndBeforeFooter(isAuthenticated),
        {fireImmediately: true}
    );

    //<editor-fold desc="configure applications">
    configureApplicationBilling(envConfig);
    configureApplicationCustomerAdministration(envConfig);
    configureApplicationCustomerConfig();
    configureApplicationCcAutomationCheck();
    configureApplicationCcBilling();
    configureApplicationCcCommissionedDataProcessing();
    configureApplicationCcDeliveryMonitor();
    configureApplicationCcOrderManagementMail();
    configureApplicationCcPackstation();
    configureApplicationCcReportingCo2();
    configureApplicationCcResponseplus();
    configureApplicationCcShipMail();
    configureApplicationCcTrackAndTrace();
    configureApplicationCcTrackmail();
    configureApplicationCustomerSpecificInformation();
    configureApplicationLimitedQuantities(envConfig);
    configureApplicationNotification();
    configureApplicationOrder();
    configureApplicationPackstation();
    configureApplicationPostcard();
    configureApplicationPostPayback();
    configureApplicationReporting()
    configureApplicationReportingProactive();
    configureApplicationResponseplus();
    configureApplicationUserExternal();
    //</editor-fold>

    registerApplicationHeader(authenticationManager);

    registerApplicationAutomationCheck(authenticationManager);
    registerApplicationBilling(authenticationManager);
    registerApplicationContent(authenticationManager, envConfig);
    registerApplicationCustomerAdministration(authenticationManager);
    registerApplicationEPostMailer();
    registerApplicationCustomerConfig(authenticationManager);
    registerApplicationLandingPage(authenticationManager, envConfig);
    registerApplicationLimitedQuantities(authenticationManager);
    registerApplicationLoginPage(authenticationManager, envConfig);
    registerApplicationNotification(authenticationManager);
    registerApplicationOrder(authenticationManager);
    // ordermanagement-mail moved into reaction
    registerApplicationReporting(authenticationManager);
    registerApplicationReportingProactive(authenticationManager, envConfig);
    registerApplicationResponseplus(authenticationManager);
    registerApplicationUserExternal(authenticationManager, envConfig);
    registerApplicationPackstation(authenticationManager, envConfig);
    registerApplicationPostcard(authenticationManager, envConfig);
    registerApplicationPostPayback(authenticationManager);

    registerApplicationFooter(authenticationManager, envConfig, trackingManager);

    logger.log(LOG_MODULE, "registered applications");
  } catch (error) {
    logger.error(LOG_MODULE, "Error registering applications", error);
  } finally {
    start({urlRerouteOnly: true});
    logger.log(LOG_MODULE, "started application");
  }
})()
    .catch(error => logger.error(LOG_MODULE, error));
