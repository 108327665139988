/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcShipMail } from "@gkuis/gkp-customerconfigshipmail-ui";

export function configureApplicationCcShipMail() {
  CcShipMail.configuration = {
    baseUri: process.env.CCSHIPMAIL_BASE_URI!,
    resourceDataBaseUri: `${process.env.CCSHIPMAIL_BASE_URI!}/v1`,
    validationRulesBaseUri: `${process.env.CCSHIPMAIL_BASE_URI!}/v1`
  };
}
