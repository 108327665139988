/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { Reporting, ReportingConfiguration, ReportingRootProps } from "@gkuis/gkp-reporting-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";

export function getReportingConfiguration(): ReportingConfiguration {
  return {
    resourceDataBaseUri: process.env.REPORTING_EXTERNAL_RESOURCE_DATA_BASE_URI!,
    validationRulesBaseUri: process.env.REPORTING_EXTERNAL_VALIDATION_RULES_BASE_URI!,
    deliveryMonitorUri: process.env.REPORTING_EXTERNAL_DELIVERY_MONITOR_URI!,
    co2ReportingUri: process.env.REPORTING_EXTERNAL_CO2REPORTING_URI!,
    orderManagementMailUri: process.env.REPORTING_EXTERNAL_ORDER_MANAGEMENT_MAIL_URI!
  };
}

export function configureApplicationReporting() {
  Reporting.configure(getReportingConfiguration());
}

export function registerApplicationReporting(authenticationManager: AuthenticationManager) {
  const reportingRootProps: ReportingRootProps = {
    authenticationManager,
    reportingConfiguration: getReportingConfiguration()
  };

  registerApplication({
    name: "@gkuis/gkp-reporting-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-reporting-ui"),
    activeWhen: ["/reporting"],
    customProps: {
      ...reportingRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}
