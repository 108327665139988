/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { UserExternal, UserExternalConfiguration, UserExternalRootProps } from "@gkuis/gkp-benutzer-ui";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { EnvConfig } from "../types/EnvConfig";

export function getUserExternalConfiguration(): UserExternalConfiguration {
  return {
    baseUri: process.env.USER_EXTERNAL_BASE_URI,
    documentUri: process.env.USER_EXTERNAL_DOCUMENT_URI!
  };
}

export function configureApplicationUserExternal() {
  UserExternal.configuration = getUserExternalConfiguration();
}

export function registerApplicationUserExternal(authenticationManager: AuthenticationManager, envConfig: EnvConfig) {
  const userExternalRootProps: UserExternalRootProps = {
    userExternalConfiguration: getUserExternalConfiguration(),
    authenticationManager,
    cmsContentRoot: envConfig.cmsContentRoot
  };

  registerApplication({
    name: "@gkuis/gkp-benutzer-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-benutzer-ui"),
    activeWhen: [
      "/user",
      "/ordermanagement-mail",
      "/reporting",
      "/customeradministration/externaluseradministration",
      "/customeradministration/createexternaluser",
      "/customeradministration/twofactorauthentication"
    ],
    customProps: {
      ...userExternalRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}