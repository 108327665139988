/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CcDeliveryMonitor } from "@gkuis/gkp-customerconfigdeliverymonitor-ui";

export function configureApplicationCcDeliveryMonitor() {
  CcDeliveryMonitor.configuration = {
    baseUri: process.env.CCDELIVERYMONITOR_BASE_URI!,
    resourceDataBaseUri: `${process.env.CCDELIVERYMONITOR_BASE_URI!}/v1`,
    validationRulesBaseUri: `${process.env.CCDELIVERYMONITOR_BASE_URI!}/v1`
  };
}
