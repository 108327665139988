/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { PostPayback, PostPaybackConfiguration, PostPaybackRootProps } from "@gkuis/gkp-postpayback-ui";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";

export function configureApplicationPostPayback() {
  PostPayback.config = {
    baseUri: process.env.POSTPAYBACK_BASE_URI!,
    imageUri: process.env.POSTPAYBACK_IMAGE_URI!
  };
}

export function registerApplicationPostPayback(authenticationManager: AuthenticationManager) {
  const postPaybackRootProps: PostPaybackRootProps = {
    authenticationManager
  };

  registerApplication({
    name: "@gkuis/gkp-postpayback-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-postpayback-ui"),
    activeWhen: ["/postpayback"],
    customProps: {
      ...postPaybackRootProps,
      domElementGetter: reactDomElementGetter
    },
  });
}