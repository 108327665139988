/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { OrderConfiguration, OrderRootProps, Order } from "@gkuis/gkp-order-ui";

export function getOrderConfiguration(): OrderConfiguration {
  return {
    recentOrderUri: process.env.ORDER_RECENT_ORDER_URI!,
    catalogUri: process.env.CATALOG_URI!,
    catalogImageUri: process.env.CATALOG_IMAGE_URI!,
    catalogDocumentUri: process.env.CATALOG_DOCUMENT_URI!,
    addressUri: process.env.ADDRESS_URI!,
    editedStockItemUri: "",
    shoppingCartUri: process.env.SHOPPING_CART_URI!,
    templateOrderUri: process.env.ORDER_TEMPLATE_ORDER_URI!,
    resourceDataBaseUri: process.env.ORDER_RESOURCE_DATA_BASE_URI!,
    validationRulesBaseUri: process.env.ORDER_VALIDATION_RULES_BASE_URI!
  };
}

export function configureApplicationOrder() {
  Order.config = getOrderConfiguration();
}

export function registerApplicationOrder(authenticationManager: AuthenticationManager) {
  const orderRootProps: OrderRootProps = {
    authenticationManager,
    orderConfiguration: getOrderConfiguration()
  };

  registerApplication({
    name: "@gkuis/gkp-order-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-order-ui"),
    activeWhen: ["/order"],
    customProps: {
      ...orderRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}