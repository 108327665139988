/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { CustomerConfig, CustomerConfigRootProps } from "@gkuis/gkp-customerconfig-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";

export function configureApplicationCustomerConfig() {
  CustomerConfig.config = {baseUri: process.env.CUSTOMER_CONFIG_BASE_URI!};
}

export function registerApplicationCustomerConfig(authenticationManager: AuthenticationManager) {
  const customerConfigRootProps: CustomerConfigRootProps = {
    authenticationManager
  };

  registerApplication({
    name: "@gkuis/gkp-customerconfig-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-customerconfig-ui"),
    activeWhen: ["/customerconfig"],
    customProps: {
      ...customerConfigRootProps,
      domElementGetter: reactDomElementGetter
    },
  });
}