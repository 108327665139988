/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { EnvConfig } from "../types/EnvConfig";
import { LandingPageRootProps } from "@gkuis/gkp-landingpage-ui";
import { getCustomerSpecificInformationConfiguration } from "./CustomerSpecificInformation";
import { LifeCycles, pathToActiveWhen, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";

export function registerApplicationLandingPage(authenticationManager: AuthenticationManager, envConfig: EnvConfig) {
  const landingPageRootProps: LandingPageRootProps = {
    authenticationManager,
    landingPageConfiguration: {},
    cmsContentRoot: envConfig.cmsContentRoot,
    csiConfig: getCustomerSpecificInformationConfiguration(),
  };

  registerApplication({
    name: "@gkuis/gkp-landingpage-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-landingpage-ui"),
    activeWhen: [pathToActiveWhen("/", true)],
    customProps: {
      ...landingPageRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}