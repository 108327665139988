/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { PrimaryNavigation, TertiaryNavigation } from "@gkuis/gkp-header-ui";
import { allOf, anyOf, AuthenticationManager, Scope } from "@gkuis/gkp-authentication";
import { FooterNavigationEntry } from "@gkuis/gkp-footer-ui";
import { EnvConfig } from "./types/EnvConfig";
import { useFeatureToggles } from "@gkuis/gkp-base-widgets";
import { LoginAuthorizationClient } from "@gkuis/gkp-benutzer-ui";
import { FetchAdapter } from "@gkuis/gkp-base-widgets";

export const primaryNavigation: PrimaryNavigation = {
  items: [
    {
      key: "parcelAndGoods",
      highlightPathPrefixes: ["/order/", "/lq"],
      children: [
        {
          key: "parcelAndGoods.ship",
          enabled: anyOf("vls:user", "vls:admin"),
          children: [
            {
              key: "parcelAndGoods.ship.shipmentOverview",
              href: "/content/vls/vc/ShipmentOverview"
            },
            {
              key: "parcelAndGoods.ship.shipmentDetails",
              href: "/content/vls/vc/ShipmentDetails"
            },
            {
              key: "parcelAndGoods.ship.transactionsOverview",
              href: "/content/vls/gw/vlsweb/TransactionsOverview"
            },
            {
              key: "parcelAndGoods.ship.startImportShipment",
              href: "/content/vls/gw/vlsweb/StartImportShipment"
            },
            {
              key: "parcelAndGoods.ship.fastPrint",
              href: "/content/vls/gw/vlsweb/FastPrint"
            },
            {
              key: "parcelAndGoods.ship.archiveManifest",
              href: "/content/vls/gw/vlsweb/ArchiveManifest"
            }
          ]
        },
        {
          key: "",
          enabled: anyOf("vls:user", "vls:admin", "limited_quantities:user"),
          children: [
            {
              key: "parcelAndGoods.ship.archiveOverview",
              enabled: anyOf("vls:user", "vls:admin"),
              href: "/content/vls/gw/vlsweb/ArchiveOverview"
            },
            {
              key: "parcelAndGoods.ship.templates",
              enabled: anyOf("vls:user", "vls:admin"),
              children: [
                {
                  key: "parcelAndGoods.ship.templates.shipmentTemplateOverview",
                  href: "/content/vls/gw/vlsweb/ShipmentTemplateOverview"
                },
                {
                  key: "parcelAndGoods.ship.templates.shipmentExportOverview",
                  href: "/content/vls/gw/vlsweb/ShipmentExportOverview"
                },
                {
                  key: "parcelAndGoods.ship.templates.shipmentImportOverview",
                  href: "/content/vls/gw/vlsweb/ShipmentImportOverview"
                },
                {
                  key: "parcelAndGoods.ship.templates.countryMappingOverview",
                  href: "/content/vls/gw/vlsweb/CountryMappingOverview"
                },
                {
                  key: "parcelAndGoods.ship.templates.psMappingImportOverview",
                  href: "/content/vls/gw/vlsweb/PsMappingImportOverview"
                },
                {
                  key: "parcelAndGoods.ship.templates.addressExportOverview",
                  href: "/content/vls/gw/vlsweb/AddressExportOverview"
                },
                {
                  key: "parcelAndGoods.ship.templates.addressImportOverview",
                  href: "/content/vls/gw/vlsweb/AddressImportOverview"
                },
                {
                  key: "parcelAndGoods.ship.templates.startTemplateImport",
                  href: "/content/vls/gw/vlsweb/StartTemplateImport"
                }
              ]
            },
            {
              key: "parcelAndGoods.ship.addressbook",
              enabled: anyOf("vls:user", "vls:admin"),
              children: [
                {
                  key: "parcelAndGoods.ship.addressbook.receiverOverview",
                  href: "/content/vls/gw/vlsweb/ReceiverOverview"
                },
                {
                  key: "parcelAndGoods.ship.addressbook.senderOverview",
                  href: "/content/vls/gw/vlsweb/SenderOverview"
                },
                {
                  key: "parcelAndGoods.ship.addressbook.startImportAddress",
                  href: "/content/vls/gw/vlsweb/StartImportAddress"
                }
              ]
            },
            {
              key: "parcelAndGoods.ship.limitedQuantities",
              enabled: anyOf("limited_quantities:user"),
              children: [
                {
                  key: "parcelAndGoods.ship.limitedQuantities.information",
                  href: "/lq/LQInfo"
                },
                {
                  key: "parcelAndGoods.ship.limitedQuantities.singleEnlistments",
                  href: "/lq/LQSingleEnlistmentOverview"
                },
                {
                  key: "parcelAndGoods.ship.limitedQuantities.infiniteEnlistments",
                  href: "/lq/LQInfiniteEnlistmentOverview"
                }
              ]
            },
            {
              key: "parcelAndGoods.ship.settings",
              enabled: anyOf("vls:user", "vls:admin"),
              children: [
                {
                  key: "parcelAndGoods.ship.settings.commonUserConfigDetails",
                  href: "/content/vls/gw/vlsweb/CommonUserConfigDetails"
                },
                {
                  key: "parcelAndGoods.ship.settings.groupProfileOverview",
                  enabled: "vls:admin",
                  href: "/content/vls/gw/vlsweb/GroupProfileOverview"
                },
                {
                  key: "parcelAndGoods.ship.settings.standardOrderSettings",
                  enabled: (scopesFromToken: (Scope | string)[]) => {
                    const featureActive = useFeatureToggles("goGreenPlusShipEnabled");
                    return (featureActive["goGreenPlusShipEnabled"] === true && anyOf("vls:admin")(scopesFromToken));
                  },
                  href: "/customerconfig/ship/standardordersettings"
                },
                {
                  key: "parcelAndGoods.ship.settings.customsOverview",
                  href: "/content/vls/gw/vlsweb/CustomsOverview"
                },
                {
                  key: "parcelAndGoods.ship.settings.emailTemplateOverview",
                  enabled: "vls:admin",
                  href: "/content/vls/gw/vlsweb/EmailTemplateOverview"
                },
                {
                  key: "parcelAndGoods.ship.settings.costCenterOverview",
                  enabled: "vls:admin",
                  href: "/content/vls/gw/vlsweb/CostCenterOverview"
                },
                {
                  key: "parcelAndGoods.ship.settings.ebayProfileOverview",
                  enabled: "vls:admin",
                  href: "/content/vls/gw/vlsweb/EbayProfileOverview"
                },
                {
                  key: "parcelAndGoods.ship.settings.bankAccountOverview",
                  enabled: "vls:admin",
                  href: "/content/vls/gw/vlsweb/BankAccountOverview"
                }
              ]
            }
          ]
        },
        {
          key: "parcelAndGoods.pickup",
          enabled: anyOf("optima:user", "optima:admin"),
          children: [
            {
              key: "parcelAndGoods.pickup.order",
              href: "/content/optima-gui/order"
            },
            {
              key: "parcelAndGoods.pickup.collectiveOrder",
              enabled: "optima:admin",
              href: "/content/optima-gui/file-order"
            },
            {
              key: "parcelAndGoods.pickup.orderManagement",
              href: "/content/optima-gui/administrate"
            },
            {
              key: "parcelAndGoods.pickup.settings",
              enabled: "optima:admin",
              href: "/content/optima-gui/settings"
            }
          ]
        },
        {
          key: "parcelAndGoods.return",
          enabled: anyOf("rosy:user", "rosy:admin"),
          children: [
            {
              key: "parcelAndGoods.return.order",
              href: "/content/dhl-rpi/gw/rpcustomerweb/OrderCustService.action"
            },
            {
              key: "parcelAndGoods.return.orderOverview",
              href: "/content/dhl-rpi/gw/rpcustomerweb/SearchOrder.action"
            },
            {
              key: "parcelAndGoods.return.settingsLegacyRemoveWithFeatureToggle",
              enabled: (scopesFromToken: (Scope | string)[]) => {
                const featureActive = useFeatureToggles("goGreenPlusReturnEnabled");
                return (featureActive["goGreenPlusReturnEnabled"] === false && anyOf("rosy:admin")(scopesFromToken));
              },
              href: "/content/dhl-rpi/gw/rpcustomerweb/RetourePortalDetails.action"
            },
            {
              key: "parcelAndGoods.return.settings",
              enabled: (scopesFromToken: (Scope | string)[]) => {
                const featureActive = useFeatureToggles("goGreenPlusReturnEnabled");
                return (featureActive["goGreenPlusReturnEnabled"] === true && anyOf("rosy:admin")(scopesFromToken));
              },
              children: [
                {
                  key: "parcelAndGoods.return.settings.generalSettings",
                  href: "/content/dhl-rpi/gw/rpcustomerweb/RetourePortalDetails.action"
                },
                {
                  key: "parcelAndGoods.return.settings.standardOrderSettings",
                  href: "/customerconfig/return/standardordersettings"
                }
              ]
            }
          ]
        },
        {
          key: "parcelAndGoods.shippingMaterial",
          enabled: "order:user",
          children: [
            {
              key: "parcelAndGoods.shippingMaterial.orderCatalog",
              href: "/order/Catalog"
            },
            {
              key: "parcelAndGoods.shippingMaterial.orderOverview",
              href: "/order/OrderOverview"
            },
            {
              key: "parcelAndGoods.shippingMaterial.orderTemplates",
              href: "/order/OrderTemplateOverview"
            },
            {
              key: "parcelAndGoods.shippingMaterial.orderShoppingCart",
              href: "/order/ShoppingCart"
            }
          ]
        }
      ]
    },
    {
      key: "mail",
      children: [
        {
          key: "mail.ordermanagement",
          children: [
            {
              key: "mail.ordermanagement.searchOrder",
              enabled: "ordermanagement_mail:search_view_menu",
              href: "/ordermanagement-mail/ordersearch"
            },
            {
              key: "mail.ordermanagement.createOrder",
              enabled: "ordermanagement_mail:create_view_menu",
              href: "/ordermanagement-mail/ordercreate"
            }
          ]
        },
        {
          key: "mail.responsePlus",
          enabled: "responseplus",
          children: [
            {
              key: "mail.responsePlus.order",
              href: "/responseplus/order"
            },
            {
              key: "mail.responsePlus.orderManagement",
              href: "/responseplus/overview"
            }
          ]
        },
        {
          key: "mail.postPayback",
          enabled: "post_payback:user",
          children: [
            {
              key: "mail.postPayback.newRefundOrder",
              href: "/postpayback/order/create"
            },
            {
              key: "mail.postPayback.overviewRefundOrder",
              href: "/postpayback/order/overview"
            }
          ]
        },
        {
          key: "mail.automationCheck",
          enabled: "automation_check:user",
          children: [
            {
              key: "mail.automationCheck.overviewTests",
              href: "/ac-web/dashboard"
            },
            {
              key: "mail.automationCheck.startNewTest",
              href: "/ac-web/wizard"
            }
          ]
        },
        {
          key: "mail.ePostMailer",
          children: [
            {
              key: "mail.ePostMailer.journal",
              enabled: "e_post_mailer:order",
              href: "/mailer/journal"
            },
            {
              key: "mail.ePostMailer.collectionBaskets",
              enabled: "e_post_mailer:admin",
              href: "/mailer/basket"
            },
            {
              key: "mail.ePostMailer.printer",
              enabled: "e_post_mailer:admin",
              href: "/mailer/printer"
            },
            {
              key: "mail.ePostMailer.attachments",
              enabled: "e_post_mailer:admin",
              href: "/mailer/attachment"
            },
            {
              key: "mail.ePostMailer.letterhead",
              enabled: "e_post_mailer:admin",
              href: "/mailer/letterhead"
            },
            {
              key: "mail.ePostMailer.companySettings",
              enabled: "e_post_mailer:admin",
              href: "/mailer/settings/organization"
            },
            {
              key: "mail.ePostMailer.userSettings",
              enabled: "e_post_mailer:order",
              href: "/mailer/settings/editor"
            }
          ]
        }
      ]
    },
    {
      key: "trackAndTrace",
      children: [
        {
          key: "trackAndTrace.parcelAndGoods",
          children: [
            {
              key: "trackAndTrace.parcelAndGoods.shipmentSearch",
              enabled: anyOf("track_parcel__shipment_search"),
              href: "/content/scc/shipmentsearch"
            },
            {
              key: "trackAndTrace.parcelAndGoods.overview",
              enabled: anyOf("track_parcel__shipment_overview"),
              href: "/content/scc/shipmentoverview"
            },
            {
              key: "trackAndTrace.parcelAndGoods.list",
              enabled: anyOf("track_parcel__shipment_list"),
              href: "/content/scc/shipmentlist"
            },
            {
              key: "trackAndTrace.parcelAndGoods.inquiryRequest",
              enabled: "investigation",
              children: [
                {
                  key: "trackAndTrace.parcelAndGoods.inquiryRequest.inquiryList",
                  enabled: allOf("rapide:inquiry_list", "track_parcel__shipment_list"),
                  href: "/content/inquiryrequest/inquiryList"
                },
                {
                  key: "trackAndTrace.parcelAndGoods.inquiryRequest.submitInquiryRequest",
                  href: "/content/inquiryrequest/submitInquiryRequest"
                },
                {
                  key: "trackAndTrace.parcelAndGoods.inquiryRequest.deliveryInquiryRequest",
                  href: "/content/inquiryrequest/deliveryInquiryRequest"
                }
              ]
            }
          ]
        },
        {
          key: "trackAndTrace.trackMail",
          children: [
            {
              key: "trackAndTrace.trackMail.overview",
              enabled: "trackmail",
              href: "/content/lcc/mailitemoverview"
            },
            {
              key: "trackAndTrace.trackMail.list",
              enabled: anyOf("trackmail", "track_mail:search"),
              href: "/content/lcc/mailitemlist"
            },
            {
              key: "trackAndTrace.trackMail.informationOnPartialServices",
              enabled: "track_mail_partial_service",
              href: "/content/online-help/verfolgen-brief/information-teilleistungen-kmtz54.data.html"
            }
          ]
        }
      ]
    },
    {
      key: "billingAndReports",
      children: [
        {
          key: "billingAndReports.billingCenter",
          enabled: "billing",
          children: [
            {
              key: "billingAndReports.billingCenter.overview",
              href: "/billing/invoice/overview"
            },
            {
              key: "billingAndReports.billingCenter.complain",
              href: "/billing/contact"
            }
          ]
        },
        {
          key: "billingAndReports.reportsParcelAndGoods",
          children: [
            {
              key: "billingAndReports.reportingCenter.overview",
              enabled: "reporting:delivery_monitor",
              href: "/reporting/deliverymonitor/"
            },
            {
              key: "billingAndReports.co2ReportingCenter.overview",
              enabled: "reporting:co2",
              href: "/reporting/co2reporting/"
            },
            {
              key: "billingAndReports.reportsParcelAndGoods.proactiveReports",
              enabled: anyOf("reporting_proactive:profile_own_billing_numbers_public", "reporting_proactive:profile_own_billing_numbers_private"),
              children: [
                {
                  key: "billingAndReports.reportsParcelAndGoods.proactiveReports.newReport",
                  href: "/reportingproactive/report/create/types"
                },
                {
                  key: "billingAndReports.reportsParcelAndGoods.proactiveReports.manageReports",
                  href: "/reportingproactive/report/reports"
                },
                {
                  key: "billingAndReports.reportsParcelAndGoods.proactiveReports.downloadReports",
                  href: "/reportingproactive/report/download/overview"
                }
              ]
            }
          ]
        },
        {
          key: "billingAndReports.mail",
          enabled: "reporting:ordermanagement_mail_view_menu",
          children: [
            {
              key: "billingAndReports.mail.orderManagement",
              href: "/reporting/ordermanagementmail"
            }
          ]
        }
      ]
    },
    {
      key: "postcard",
      enabled: "postcard:user",
      children: [
        {
          key: "postcard",
          children: [
            {
              key: "postcard.cardAdministration",
              href: "/postcard/overview"
            },
            {
              key: "postcard.transactionOverview",
              enabled: "postcard:transaction_overview",
              href: "/postcard/transactionOverview"
            }
          ]
        }
      ]
    },
    {
      key: "packstation",
      enabled: "packstation:field_service_supply",
      children: [
        {
          key: "packstation",
          children: [
            {
              key: "packstation.fieldStaffAdministration",
              href: "/packstation/fieldStaffAdministration"
            },
            {
              key: "packstation.fieldServiceAdministration",
              href: "/content/packstation-broker/B2bGatewayGKP/"
            }
          ]
        }
      ]
    }
  ]
};

export const getTertiaryNavigation = (authenticationManager: AuthenticationManager): TertiaryNavigation => ({
  myMessages: {
    key: "myNotifications",
    enabled: "external",
    href: "/notification/"
  },
  myAccount: {
    key: "myAccount",
    enabled: "external",
    items: [
      {
        key: "myAccount.myData",
        href: "/customeradministration/mydata/userdata"
      },
      {
        key: "myAccount.changePassword",
        onClick: () => authenticationManager.updatePassword()
      },
      {
        key: "myAccount.twoFactorAuthentication",
        href: "/user/twofactorauthentication"
      },
      {
        key: "myAccount.loginAuthorizations",
        enabled: async () => {
          const loginAuthorizationClient = new LoginAuthorizationClient(new FetchAdapter(authenticationManager));
          return (await loginAuthorizationClient.hasLoginAuthorization(authenticationManager.userId));
        },
        href: "/user/loginauthorization"
      },
      {
        key: "myAccount.userOverview",
        enabled: "customer_administration:user_admin_view_menu",
        href: "/customeradministration/externaluseradministration"
      },
      {
        key: "myAccount.customerDataOverview",
        enabled: "customer_administration",
        href: "/customeradministration/customerdataoverview"
      },
      {
        key: "myAccount.addNewProduct",
        enabled: "customer_administration",
        href: "/customeradministration/manageproducts"
      },
      {
        key: "myAccount.helpAndInformation",
        href: "/content/content/helpAndInformation",
        icon: "question"
      },
      {
        key: "myAccount.logout",
        icon: "logout",
        onClick: () => {
          window.postMessage({ // better use basewidgets, but mind dependency consequence?
            event_id: "customPageView",
            data: {
              page: {name: "Nutzer:[\"Verlassen\" Icon] Abmelden"},
              application: {
                name: "gkp",
                events: ["customPageView"]
              }
            }
          }, window.location.origin);
          void authenticationManager.logout();
        }
      }
    ]
  }
});

export const getFooterNavigation = (envConfig: EnvConfig, loadAndShowConsentDialog: () => void): FooterNavigationEntry[] => [
  {
    key: "legalNotice",
    hrefLocalizationKey: envConfig.cmsContentRoot === "https://gitdhl.dhl.de" ? "legalNotice.href.npi" : "legalNotice.href.pi",
    target: "_blank"
  },
  {
    key: "dataProtection",
    hrefLocalizationKey: "dataProtection.href",
    target: "_blank"
  },
  {
    key: "cookieSettings",
    onClick: () => loadAndShowConsentDialog()
  },
  {
    key: "commissionedDataProcessing",
    hrefLocalizationKey: "commissionedDataProcessing.href",
    target: "_blank"
  }
];
