/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { Notification, NotificationRootProps } from "@gkuis/gkp-notification-ui";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { LifeCycles, registerApplication } from "single-spa";
import { reactDomElementGetter } from "../reactDomElementGetter";

export function configureApplicationNotification() {
  Notification.config = {baseUri: process.env.NOTIFICATION_BASE_URI!};
}


export function registerApplicationNotification(authenticationManager: AuthenticationManager) {
  const notificationRootProps: NotificationRootProps = {
    authenticationManager
  };

  registerApplication({
    name: "@gkuis/gkp-notification-ui",
    app: () => System.import<LifeCycles>("@gkuis/gkp-notification-ui"),
    activeWhen: ["/"],
    customProps: {
      ...notificationRootProps,
      domElementGetter: reactDomElementGetter,
    },
  });
}